@import '../styles/functions.scss';

.hero {
  height: 100vh;
  min-height: 620px;
  width: 100%;
  position: relative;


  &-bg {
    height: calc(100% - 100px);
    position: relative;

    & > * {
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
    }

    .gradient {
      z-index: 110;
      mix-blend-mode: overlay;
      //background: radial-gradient(circle at 30% 30%, rgba(255,255,255,1) 4%, rgba(0,0,0,1) 100%);
    }

    .solid-color {
      transition:
              width .6s cubic-bezier(.8,-0.23,.15,1.2),
              max-width .6s cubic-bezier(.8,-0.23,.15,1.2),
              background .6s ease-in-out;
    }

    .solid-color#sell-car {
      z-index: 100;
      background: #b7e80d;

      #hero[data-active="buy-car"] & {
        background: #d1e80d;
      }
    }

    .solid-color#buy-car {
      width: 5vw;
      max-width: 30px;
      right: 0;
      left: auto;
      z-index: 105;
      background: #464646;

      #hero[data-active="buy-car"] & {
        width: calc(100vw - 45px);
        max-width: 95vw;
        background: #d2d2d2;
      }
    }
  }

  .slides {
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;

    @media screen and (max-width: 420px) {
      & {
        bottom: 40px;
      }
    }

    .content {
      height: 450px;
      width: 0;
      max-width: 100vw;
      position: absolute;
      top: 200px;
      overflow: hidden;
      transition: .24s width ease-in;
      z-index: 50;

      .hero-box {
        position: absolute;
        top: 0;
        width: 350px;
        height: 350px;
        background: radial-gradient(circle at right top, rgba(255,255,255,.5) 4%, rgba(255,255,255,.3) 100%);


        &-inner {
          padding: 0 20px;
          position: relative;
          width: 100%;
          z-index: 250;
          height: 350px;
          display: table-cell;
          vertical-align: middle;

          .text span {
            font-family: 'Oswald';
            font-size: fsize(46px);
            line-height: fsize(38px);
            text-transform: uppercase;
            color: #383838;
            display: block;

            &.line2 {
              font-size: fsize(56px);
              line-height: fsize(50px);
            }
          }

          .hero-btn {
            position: absolute;
            display: block;
            z-index: 300;
            bottom: -20px;

            .btn {
              font-size: fsize(22px);
              font-weight: 400;
              width: calc(100% + 40px);
              padding: 0 20px;

              &:hover {
                background: #464646;
                box-shadow: 0 0 0 2px inset #464646;
                color: #ffffff;
              }
              &:active {
                background: darken(#464646, 10);
              }
            }
          }
        }
      }
    }

    .car-img {
      position: absolute;
      bottom: -20px;
      max-width: 150vw;
      z-index: 10;
      transition: transform .9s cubic-bezier(.8,-0.23,.15,1.2);

      img {
        max-height: 20vh;
      }

      @media screen and (max-width: 460px) {
        & {
          bottom: 10px;
        }
      }
    }

    .slide {
      &#sell-car {
        .content {
          left: calc(50% + 4px);
        }
        .car-img {
          right: calc(50% - 20vw);
          transform: translateX(-250vw);
          @media screen and (max-width: 768px) {
          }
        }
        .hero-box {
          background: linear-gradient(35deg, rgba(209, 233, 17,.2) -10%, rgba(252, 255, 21, .6) 130%);
        }
        .hero-btn {
          right: -50px;
        }
      }

      &#buy-car {
        .content {
          right: calc(50% + 4px);
        }
        .car-img {
          left: calc(50% - 20vw);
          transform: translateX(250vw);
        }
        .hero-box {
          right: 0;
        }
        .hero-btn {
          left: -30px;
        }
      }
    }
  }

  &#hero[data-active="sell-car"] {
    .slide#sell-car {
      .content {
        width: 450px;
        transition-delay: .32s;
      }
      .car-img {
        transform: translateX(0);
      }
    }
  }

  &#hero[data-active="buy-car"] {
    .slide#buy-car {
      .content {
        width: 450px;
        transition-delay: .32s;
      }
      .car-img {
        transform: translateX(0);
      }
    }
  }

  @media screen and (max-width:768px) {
    .slide {
      .content {
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
  }

  @media screen and (max-height: 800px) {
    .slide {
      .content {
        top: auto;
        bottom: 152px;
      }
    }
  }
}
