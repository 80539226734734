@import 'functions.scss';


@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 10px;

    &-track {
      background: #f1f1f1;
    }

    &-thumb {
      background: #b6b6b6;
      transition: .12s ease-out background;

      &:hover {
        background: darken(#b6b6b6, 2);
      }
    }
  }
}

@mixin useShadow() {
  .page-content {
    overflow: hidden;
  }

  .inner {
    display: block;
    position: relative;
    background: #ffffff;
    padding: 60px 0;
    z-index: 260;
    overflow: hidden;

    &-content {
      position: relative;
      display: block;
      z-index: 300;
    }
  }

  &:before, &:after {
    content: " ";
    position: absolute;
    z-index: 190;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100%;
    width: 60%;
    height: 20px;
    background: #000;
    filter: blur(15px);
    opacity: .3;
  }

  &:before {
    top: 10px;
  }

  &:after {
    bottom: 10px;
  }
}


html, body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #fff;
}

html {
  @include scrollbar()
}

a, a:visited, a:active {
  text-decoration: none;
  color: initial;
}

p {
  line-height: 1.5rem;
}

.section a {
  color: #9fc700 !important;
  transition: .12s color ease-in-out;

  &:hover {
    color: #d1e80d !important;
  }
}

ul {
  li {
    list-style: none;
  }
}

h2 {
  font-family: 'Oswald';
  font-weight: 300;
  font-size: fsize(52px);
  line-height: fsize(52px);
  text-align: center;
  margin-bottom: 3rem;
  color: #383838;
}


.btn {
  background: #464646;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: .12s ease-out background-color,
  .12s ease-out box-shadow,
  .12s ease-out color;

  &:hover {
    background: lighten(#464646, 15);
  }

  &:active {
    background: lighten(#464646, 55);
    color: #464646;
    box-shadow: 0 0 0 2px inset #464646;
    transition: .22s ease-out background-color,
    .22s ease-out box-shadow,
    .22s ease-out color;
  }

  &.btn-light {
    background: none;
    color: #464646;
    box-shadow: 0 0 0 2px inset #464646;

    &:hover {
      background: rgba(70, 70, 70, .2);
      box-shadow: 0 0 0 2px inset lighten(#464646, 15);
    }

    &:active {
      background: #464646;
      color: #fff;
      box-shadow: 0 0 0 2px inset #464646;
    }
  }

  &.btn-round {
    padding: 4px 32px;
    font-family: 'Oswald';
    height: 55px;
    font-weight: 300;
    text-transform: uppercase;
    font-size: fsize(18px);
    border-radius: 27px;
  }
}


.close {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(212, 212, 212);
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 18px;
  z-index: 500;
  cursor: pointer;
  transition: .12s ease-in background;
  text-align: center;

  &:hover {
    background: rgb(224, 224, 224);
  }
}

.page-content {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 2vw;


}

.page-content > p,
.inner-content > p {
  margin: 0 auto;
  line-height: 1.5rem;
  margin-bottom: 72px;
  max-width: 620px;
  color: #606060;
  text-align: center;
}

.section {
  position: relative;
}

.inner, .inner-content {
  position: relative;
  z-index: 300;
}


.round-icon {
  box-shadow: 6px 6px 18px rgba(0, 0, 0, .1);
  border-radius: 100%;
  width: 140px;
  height: 140px;
  max-width: 140px;
  max-height: 140px;
  text-align: center;
  vertical-align: middle;
  display: table;
  z-index: 350;
  background: #fff;

  @media screen and (max-width: 960px) {
    & {
      width: 15vw;
      height: 15vw;
      min-width: 110px;
      min-height: 110px;
    }
  }

  .img {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    img {
      max-width: 60%;
    }
  }
}


#root {
  position: relative;
}

#vertical-line {
  position: absolute;
  z-index: 150;
  top: 200px;
  left: calc(50% - 4px);
  bottom: 0;
  width: 8px;
  display: block;
  background: #464646;

  @media screen and (max-width: 768px) {
    & {
      top: 568px;
    }
  }
  @media screen and (max-height: 800px) {
    & {
      top: calc(100vh - 602px);
    }
  }
  @media screen and (max-height: 620px) {
    & {
      top: 18px;
    }
  }
  @media screen and (max-height: 800px) and (max-width: 768px) {
    & {
      top: calc(100vh - 233px);
    }
  }
  @media screen and (max-height: 620px) and (max-width: 768px) {
    & {
      top: 388px;
    }
  }
}


#call-us {
  position: fixed;
  z-index: 480;
  right: 30px;
  bottom: 30px;
  transition: .24s ease-out all;


  .call-us-btn {
    width: 210px;
    font-family: 'Oswald';
    font-size: 1.7rem;
    position: relative;
    background: radial-gradient(circle at 10% 90%, #fcfe19, #cbee11);
    height: 56px;
    border-radius: 56px;
    padding: 10px;
    box-shadow: 0 1px 1px rgba(0,0,0,.2);
    overflow: hidden;
    transition: .12s ease-out box-shadow,
                .12s ease-out transform,
                .12s ease-out width;

    &:hover {
      box-shadow: 2px 6px 12px rgba(0,0,0,.2);
      transform: translateY(-5px);
    }
  }

  a {
    color: #383838 !important;
  }

  img {
    vertical-align: middle;
  }
  .number {
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    width: 180px;
    left: 75px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.small {
    bottom: 10px;
    right: 10px;

    .call-us-btn {
      width: 56px;
      &:hover {
        width: 210px;
      }
    }
  }
}

header#main-header {
  position: absolute;
  width: 100%;
  display: block;
  top: 0;
  z-index: 1000;
  padding: 20px 0;

  .page-content {
    display: grid;
    grid-template-columns: 300px 1fr;
  }

  .logo {
    img {
      max-width: 180px;
    }
  }

  nav.menu {
    font-family: 'Oswald light';
    font-size: 1.4rem;
    text-align: right;

    .menu-item {
      display: inline-block;
      margin-right: 15px;

      a {
        color: #383838;
      }
    }
  }

  nav.burger-menu {

    .menu-btn {
      width :56px;
      height: 56px;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 500;
      cursor: pointer;
      overflow: hidden;

      & > * {
        width: 24px;
        height: 2px;
        border-radius: 2px;
        background: #383838;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        transition: .12s ease-in-out all;

        &.line1 {
          transform: translate(-50%, calc(-50% - 8px));
        }
        &.line2 {
          transform: translate(-50%, -50%);
        }
        &.line3 {
          transform: translate(-50%, calc(-50% + 8px));
        }
      }

      &:after {
        content: " ";
        display: block;
        width: 200%;
        height: 100px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: radial-gradient(circle at 10% 90%, #fcfe19, #cbf100);
        transition: .12s ease-in-out transform;
        transform: translateX(0);
        z-index: 0;
      }

      &:hover {
        &:after {
          transform: translateX(-50%);
        }
      }
    }

    .menu-container {
      position: fixed;
      right: 0;
      top: 0;
      background: radial-gradient(circle at 10% 90%, #fcfe19, #cbee11);
      padding: 12px 30px;
      transform: translateX(110%);
      transition: .24s transform cubic-bezier(.8,-0.23,.15,1.2);


      .menu-item {
        display: block;
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
      }

    }

    &.open-menu {
      .menu-btn {
        .line1 {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        .line2 {
          transform: translate(-50%, -50%);
          opacity: 0;
          width: 20px;
        }
        .line3 {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      .menu-container {
        transform: translateX(0);
      }
    }
  }
}


.box {
  z-index: 200;
  position: relative;
  margin: 120px 0;

  &.with-shadow {
    @include useShadow()
  }
}

.single-page {
  display: block;
  position: relative;
  padding: 140px 0;

  & > .box {
    margin: 0 !important;
  }
}


#o-nas {
  .content {
    width: 80%;
    max-width: 920px;
    margin: 0 auto;

    h2 {
      margin-bottom: 12px;
    }

    p {
      text-align: justify;
    }
  }
}


#co-zyskasz {
  overflow: visible;

  .inner, .page-content {
    overflow: visible;
  }
}

.features-list {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
  padding-bottom: 32px;
  grid-template-areas: 'empty-space grid';

  &#co-nas-wyroznia {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: 'grid empty-space';
  }

  @media screen and (max-width:960px) {
    & {
      grid-template-columns: 1fr 5fr;

      &#co-nas-wyroznia {
        grid-template-columns: 5fr 1fr;
      }
    }
  }
  @media screen and (max-width:720px) {
    & {
      grid-template-columns: 1fr;
      grid-template-areas:  'empty-space'
                            'grid';
      &#co-nas-wyroznia {
        grid-template-columns: 1fr;
        grid-template-areas:  'empty-space'
                              'grid';
      }
      .feature-empty-space {
        height: 6vh;

        &:after {
          right: -2vw !important;
          left: -2vw !important;
        }
      }
    }
  }

  .feature-empty-space {
    grid-area: empty-space;
    display: block;
    position: relative;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      background-color: #e4fd06;
      background-image: url('../img/features_bg_1.jpg');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      top: -20px;
      bottom: -20px;
      left: -2vw;
      right: -2vw;
      z-index: -1;
    }
  }

  &#co-z-nami-zyskasz {
    .feature-empty-space:after {
      right: -50%;
    }
  }
  &#co-nas-wyroznia {
    .feature-empty-space:after {
      background-image: url('../img/features_bg_2.jpg');
      top: -50%;
      left: -50%;
    }
  }

  .feature-list-grid {
    grid-area: grid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 145px;
    grid-gap: 10px;

    @media screen and (max-width:860px) {
      & {
        grid-template-columns: 1fr 1fr;
      }
    }
    @media screen and (max-width:420px) {
      & {
        grid-template-columns: 1fr;
      }
    }
  }

  .single-feature {
    text-align: center;
    position: relative;
    height: 145px;
    cursor: pointer;

    .single-feature-main {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      background: #fff;
      box-shadow: 0 5px 10px rgba(0,0,0,.16);
      transition: .1s all;
    }

    .feature-icon {
      margin: 6px auto;
      img {
        max-height: 56px;
      }
    }

    .feature-title {
      font-family: 'Oswald';
      font-weight: 300;
      font-size: 1.3rem;
      line-height: 1.4rem;

      display: flex;
      height: 45px;
      justify-content: center;
      align-items: center;

      span {
        display: block;

      }
    }

    .feature-desc {
      width: 100%;
      height: 0;
      overflow: hidden;

      &-inner {
        width: 100%;
        height: auto;
        padding-bottom: 24px;
      }

      p {
        text-align: left;
      }
    }

    .close {
      display: none;
    }

    &.sf-active {
      .close {
        display: block;
      }

      .feature-desc {
        transition: .24s ease-in-out height;
      }

      .single-feature-main {
        padding: 10px 24px;
      }
    }
  }
}

.features-interruption {
  max-width: 1200px;
  margin: 52px auto;

  h3 {
    font-family: 'Oswald';
    font-size: 1.9rem;
    font-weight: 300;
    margin-bottom: 12px;
  }

  p {
    padding-bottom: 32px;
    max-width: 720px;
  }
}


#gifts {
  margin-bottom: 240px;
  z-index: 195;

  .page-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }

  .gift {
    display: grid;
    grid-column-gap: 40px;

    &#gift-charger {
      grid-template-columns: 1fr 306px;
      grid-template-areas: 'content img';

      .gift-img:before {
        background-image: url('../img/gift_bg_l.jpg');
        background-position: top right;
      }

      .gift-img img {
        right: 20px;
      }

      .gift-content {
        text-align: right;
      }
    }

    &#gift-maxiecu {
      grid-template-columns: 306px 1fr;
      grid-template-areas: 'img content';

      .gift-img:before {
        background-image: url('../img/gift_bg_r.jpg');
        background-position: top left;
      }

      .gift-img img {
        left: 20px;
      }
    }

    @media screen and (max-width: 1300px) {
      & {
        grid-template-columns: 1fr !important;
        grid-template-rows: 45vw 1fr !important;
        grid-row-gap: 90px;
        grid-template-areas:
                'img'
                'content' !important;

        &#gift-maxiecu {
          .gift-content {
            padding-left: 20px;
          }
        }

        &#gift-charger {
          .gift-content {
            padding-right: 20px;
          }
        }

      }
    }

    &-content {
      grid-area: content;
    }

    &-title {
      font-family: 'Oswald';
      font-size: fsize(36px);
      line-height: fsize(36px);
      text-transform: uppercase;
      color: #383838;
    }

    &-img {
      grid-area: img;
      position: relative;
      width: 100%;
      min-height: 250px;

      img {
        z-index: 190;
        display: block;
        position: absolute;
        bottom: -30px;
        max-width: 40vw;
      }

      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100%;
        z-index: 50;
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }


  @media screen and (max-width: 620px) {
    & {
      @include useShadow();
      .page-content {
        grid-template-columns: 1fr;
        background: #fff;
        display: block;
        z-index: 260;
        position: relative;
        padding-top: 36px;
      }

      .gift {
        margin-bottom: 32px;
        &-content {
          text-align: justify !important;
        }
        &-img {
          width: 200px;
          margin: 0 auto;
        }
      }
    }
  }

  @media screen and (max-width: 460px) {
    & {
      .gift {
        grid-template-rows: 1fr !important;
      }
    }
  }
}


#sprzedaj {
  margin-bottom: 120px;


  @media screen and (max-width: 960px) {
    & {
      @include useShadow();
    }
  }

  .page-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    align-items: center;
    position: relative;
    z-index: 200;

    @media screen and (max-width: 960px) {
      & {
        grid-template-columns: 1fr;
        background: #fff;
        padding: 0;
      }
    }
  }

  .steps {
    position: relative;
    margin-bottom: 56px;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      display: block;
      max-width: 45vw;
      z-index: 270;
      width: 340px;
      height: 340px;
      background-image: url('../img/handshake.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      transform: translate(-30%, -35%);
    }

    .inner-content {
      & > h2 {
        margin-bottom: 12px;
      }

      & > p {
        margin-bottom: 72px;
      }
    }

    &-list {
      display: grid;
      grid-row-gap: 80px;
    }

    .step {
      display: grid;
      grid-column-gap: 40px;
      align-items: center;
      padding-right: 60px;

      @media screen and (max-width: 1600px) {
        & {
          padding-right: 3.75vw;
        }
      }

      @media screen and (max-width: 960px) {
        & {
          grid-column-gap: 2.5vw;
          padding: 0 3.75vw;
        }
      }

      &:nth-child(even) {
        grid-template-columns: 140px 1fr;
        grid-template-areas: 'icon desc';
      }

      &:nth-child(odd) {
        grid-template-columns: 1fr 140px;
        grid-template-areas: 'desc icon';
      }

      .step-ico {
        grid-area: icon;

        img {
          max-width: 80%;
        }
      }

      .step-content {
        grid-area: desc;

        .step-title {
          font-size: fsize(28px);
          line-height: fsize(28px);
          color: #383838;
          font-family: 'Oswald';
          text-transform: uppercase;
          margin-bottom: 7px;
        }
      }
    }
  }

  .form {
    position: relative;

    .inner {
      background: radial-gradient(ellipse at top right, #f5f5f5 0%, #dadada 100%);
      padding-top: 0;
    }

    &-header {
      min-height: 240px;
      background-image: url('../img/sell_form_bg.jpg');
      background-position: center center;
      background-size: cover;
      position: relative;

      .form-title {
        background: linear-gradient(35deg, #ddf60a 0%, #fcfe26);
        font-size: fsize(42px);
        line-height: fsize(42px);
        font-family: 'Oswald';
        text-transform: uppercase;
        display: inline-block;
        padding: 20px 45px;
        position: absolute;
        right: -10px;
        bottom: 30px;
      }
    }

    &-container {
      padding: 30px;

      p {
        margin-bottom: 40px;
      }

      .form-content {
        background: #ffffff;
        border-radius: 12px;
        padding: 45px 35px;
        margin-bottom: 30px;
      }
    }
  }
}


.form-set {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 35px;
  margin-bottom: 35px;

  &.single-column {
    grid-template-columns: 1fr;
  }

  &.extra-fields {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-row-gap: 10px;
  }

  &#form-terms {
    grid-row-gap: 12px;
    grid-template-rows: repeat(auto-fill, 30px);
  }

  &#form-photos {

    .dropzone {
      width: 100%;
      text-align: center;
      min-height: 156px;
      background: #f8f8f8;
      cursor: pointer;

      .text {
        padding: 60px 20px;

        .title {
          font-family: 'Oswald';
          font-weight: 300;
          text-transform: uppercase;
          margin: 0;
          margin-bottom: 7px;
          font-size: fsize(20px);
          line-height: fsize(20px);
          color: #464646;
        }

        .desc {
          font-size: .7rem;
          line-height: .8rem;
          color: #a1a8ad;
          margin: 0;
        }
      }

      .photos {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        grid-gap: 7px;
        padding: 7px;

        .photo-preview {
          padding: 3px;
          background: #fff;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.form-set-title {
  text-transform: uppercase;
  font-family: 'Oswald';
  font-size: .9rem;
  margin-left: 10px;
  color: #403f3f;
  margin-bottom: 22px;

  span {
    color: #c8d422;
  }
}

.form-footer {
  text-align: center;
}

.form-field {
  display: block;
  position: relative;

  &-error {
    position: absolute;
    color: #ff6b6b;
    font-size: .6rem;
    bottom: -2px;
    right: 2px;
  }

  &.text-field {
    height: 30px;
    cursor: text;

    input {
      font-size: 1rem;
      margin-top: 2px;
      line-height: 29px;
      position: relative;
      display: block;
      z-index: 300;
      width: calc(100% - 20px);
      padding: 0 10px;
      border: none;
      background: none;
      border-bottom: 1px solid #acacac;
      transition: .12s border-color;

      &:focus {
        outline: none;
        border-color: #e6f51e;
      }
    }

    label {
      position: absolute;
      display: block;
      z-index: 290;
      bottom: 0;
      font-size: .9rem;
      line-height: 12px;
      padding: 7px 10px;
      color: #a1a8ad;
      transition: .12s opacity,
      .12s transform,
      .12s font-size;
    }

    &.none-empty {
      label {
        transform: translate(-5px, -18px);
        font-size: .7rem;
        opacity: .6;
      }
    }

    &.with-error {
      input {
        background: rgba(255, 0, 0, .05);
        border-color: red;
      }
    }
  }

  &.check-field {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 0;
    align-items: center;

    label {
      font-size: .9rem;
      cursor: pointer;
      color: #a1a8ad;
    }

    input {
      cursor: pointer;
    }

    &.with-error {
      .form-field-error {
        top: -8px;
        left: 32px;
      }

      label {
        animation: required 2s infinite ease-out;
      }
    }
  }
}

@keyframes required {
  0% {
    color: initial;
  }
  50% {
    color: red;
  }
  100% {
    color: initial;
  }
}


#oferowane-samochody {

  .inner {
    background: radial-gradient(ellipse at top right, #f5f5f5 0%, #dadada 100%);
    overflow: visible;
  }

  .section {
    padding-bottom: 180px;
  }

  .content {
    text-align: center;
    position: relative;
  }

  p {
    color: #606060;
  }

  .alt-title {
    color: #a5a5a5;
    font-family: 'Oswald';
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 1.8rem;
  }

  .alt-offers {
  }

  .floating-car {
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);

    img {
      max-height: 190px;
      max-width: 80vw;
    }
  }
}

.offers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
  margin: 50px 0;


  .offer {
    max-width: 320px;
    width: 100%;
    z-index: 100;
    display: block;
    background: #f0f0f0;
    justify-self: center;
    position: relative;
    cursor: pointer;
    transform: translateX(10px);
    transition: .24s ease-in transform;

    &-inner {
      position: relative;
      z-index: 100;
      display: block;
      background: #fff;
    }

    &-img {
      position: relative;
      width: 100%;
      height: 220px;
      transform-style: preserve-3d;
      perspective: 200vw;
      transform: translate(-10px, -10px);
      transition: .12s ease-out transform;

      .img-side {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #e2e2e2;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        backface-visibility: hidden;
        transition: .72s ease-out transform;
        transform: rotateY(0deg);

        &.first-side {
        }

        &.sec-side {
          transform: rotateY(-180deg);
        }
      }
    }

    &:after {
      content: " ";
      position: absolute;
      display: block;
      bottom: 10px;
      left: 50%;
      width: 0;
      transform: translateX(-50%) scaleX(5);
      background: rgba(0, 0, 0, 1);
      filter: blur(5px);
      height: 2px;
      z-index: 1;
      border-radius: 100%;
      transition: .24s ease-out all;
    }

    &:hover {
      transform: translate(10px, -5px);
      transition: .12s ease-in transform;

      &:after {
        bottom: -15px;
        width: 50px;
        background: rgba(0, 0, 0, 0.8);
        filter: blur(10px);
        height: 5px;
      }

      .offer-img {
        transform: translate(-10px, -10px);

        .first-side {
          transform: rotateY(180deg);
        }

        .sec-side {
          transform: rotateY(0deg);
        }
      }
    }
  }
}


.offer {

  &-data {
    padding: 18px 15px;
    padding-top: 10px;
    position: relative;
    text-align: left;
    font-size: .9rem;

    &-title {
      font-family: 'Oswald';
      color: #383838;
      font-size: fsize(28px);
      text-transform: uppercase;
      margin-bottom: 7px;
    }

    &-price {
      position: absolute;
      bottom: 16px;
      right: 15px;
      color: #b4ce06;
      font-size: 18px;
      font-family: 'Oswald';
    }

    &-row {
      display: grid;
      grid-template-columns: 60px 1fr;

      .label {
        color: #898888;
        font-size: .8rem;
      }

      .value {
        color: #383838;
      }
    }
  }
}

.offer-details {

  &-box {
    position: fixed;
    display: block;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 1300px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 10px 10vw 100vw rgba(0, 0, 0, .4);
    z-index: 1000;
    transform: translateY(-100vh);
    transition: .22s ease-out transform,
    .22s ease-out opacity;
  }

  &.display {
    .offer-details-box {
      transform: translateY(0);
      display: block;
      opacity: 1;
      transition: .32s ease-in transform,
      .32s ease-in opacity;
    }
  }

  &-inner {
    text-align: left;

  }

  &-content {
    height: calc(100vh - 20px);
    overflow-y: scroll;
    padding: 10px;
    @include scrollbar()
  }

  .photos {
    .swiper {
      &-slide {
        text-align: center;

        img {
          max-width: 100%;
          max-height: 80vh;
        }
      }
    }
  }

  .photos-roll {
    .swiper {
      &-slide {
        text-align: center;
        max-height: 80px;

        img {
          max-width: 100%
        }
      }
    }
  }

  .od-details {
    padding: 0 20px;
    margin-top: 12px;
    margin-bottom: 32px;
    position: relative;

    .offer-data-row {
      grid-template-columns: 90px 1fr;
    }

    &:after {
      content: " ";
      position: absolute;
      left: 10px;
      right: 10px;
      height: 1px;
      margin: 0 auto;
      bottom: -16px;
      background: #e2e2e2;
      display: block;
    }

  }

  .od-description {
    padding: 0 20px;
    margin-bottom: 36px;
    color: #383838;

    ul {
      padding-left: 12px;
      li {
        list-style-type: '-';
        padding-left: 7px;
      }
    }
  }

}

.footer {
  background: #383838;
  color: #dadada;
  position: relative;
  z-index: 180;
  display: block;

  .inner {
    padding: 30px 0;
  }

  a, a:active, a:visited {
    color: #a0ac5c;
    transition: .12s color ease-out;

    &:hover {
      color: #d1e80d;
    }
  }

  .second-footer {
    background: darken(#383838, 5);
    padding: 9px 0;
    font-size: .9rem;
    color: lighten(#383838, 25);
    text-align: center;
  }
}




.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 120px 0;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
